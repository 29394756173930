import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./LookAlikeProducts.scss";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import LookAlikeProducts from "app/pages/.shared/LookAlikeProduct/LookAlikeProducts";
import { fetchLookAlikeProducts } from "app/pages/.shared/LookAlikeProduct/lookAlikeProductsActionCreators";
import { resolutionPropType } from "app/utils/propTypes";

const LookAlikeProductsContainer = ({ productId, fetchLookAlikeProducts, resolution }) => {
	const [products, setProducts] = useState([]);

	useEffect(() => {
		if (productId) {
			fetchLookAlikeProducts(productId).then(res => {
				if (res.status === 200 && res.data && res.data.length > 0) {
					setProducts(res.data);
				}
			});
		}
	}, [productId]);

	return <LookAlikeProducts products={products} resolution={resolution} />;
};

LookAlikeProductsContainer.propTypes = {
	productId: PropTypes.string,
	fetchLookAlikeProducts: PropTypes.func,
	resolution: resolutionPropType,
};

const mapStateToProps = state => {
	return {
		resolution: state.resolution,
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			fetchLookAlikeProducts,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LookAlikeProductsContainer);
