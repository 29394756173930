import React from "react";
import { stringify } from "qs";
import "./mail-to-button.scss";
import PropTypes from "prop-types";
import IconEmail from "app/pages/.shared/static/icons/IconEmail";

export class MailToButton extends React.PureComponent {
	constructor() {
		super();
		this.onOpenMailbox = this.onOpenMailbox.bind(this);
	}

	onOpenMailbox() {
		if (typeof this.props.onClick === "function") {
			this.props.onClick();
		}
	}

	render() {
		const { subject, body, label } = this.props;
		let mailToOptions = {};

		if (subject) {
			mailToOptions.subject = subject;
		}

		if (body) {
			mailToOptions.body = body;
		}

		const mailToQueryString = stringify(mailToOptions, { encode: false });

		return (
			<a
				className="mail-to-button"
				rel="noopener noreferrer"
				target="_blank"
				onClick={this.onOpenMailbox}
				href={`mailto:?${mailToQueryString}`}
			>
				<span className="mail-to-button__icon">
					<IconEmail height={20} width={20} />
				</span>
				<span className="mail-to-button__label">{label}</span>
			</a>
		);
	}
}

MailToButton.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	subject: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	onClick: PropTypes.func,
};

export default MailToButton;
