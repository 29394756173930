import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { LOADING_BAR_STATUS } from "app/constants";
import PropTypes from "prop-types";
import "app/pages/.shared/LoadingBar/LoadingBar.scss";

export class LoadingBarComponent extends React.PureComponent {
	constructor() {
		super();
		this.state = {
			status: LOADING_BAR_STATUS.HIDDEN,
		};
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.loadingBarStatus === LOADING_BAR_STATUS.FINISHED &&
			prevProps.loadingBarStatus === LOADING_BAR_STATUS.LOADING
		) {
			this.setState(
				() => {
					return {
						status: LOADING_BAR_STATUS.FINISHED,
					};
				},
				() => {
					setTimeout(
						() =>
							this.setState({
								status: LOADING_BAR_STATUS.HIDDEN,
							}),
						800
					);
				}
			);
		} else if (
			this.props.loadingBarStatus === LOADING_BAR_STATUS.LOADING &&
			prevProps.loadingBarStatus !== this.props.loadingBarStatus
		) {
			this.setState({
				status: LOADING_BAR_STATUS.LOADING,
			});
		} else if (this.props.loadingBarStatus === LOADING_BAR_STATUS.HIDDEN) {
			this.setState({
				status: LOADING_BAR_STATUS.HIDDEN,
			});
		}
	}

	render() {
		const { status } = this.state;

		return (
			<div
				className={classNames(
					{
						"loading-bar--loading": status === LOADING_BAR_STATUS.LOADING,
						"loading-bar--finished": status === LOADING_BAR_STATUS.FINISHED,
					},
					"loading-bar"
				)}
			/>
		);
	}
}

LoadingBarComponent.propTypes = {
	loadingBarStatus: PropTypes.string,
};

const mapStateToProps = state => ({ loadingBarStatus: state.loadingBarStatus });

export const LoadingBar = connect(mapStateToProps)(LoadingBarComponent);
