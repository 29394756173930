import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

// source : https://github.com/danilowoz/react-content-loader/tree/master/src
const LoaderBar = ({
	height,
	width,
	primaryColor,
	primaryOpacity,
	animate,
	secondaryColor,
	secondaryOpacity,
	speed,
	gradientRatio,
	interval,
	idGradient = uuidv4(),
}) => {
	const keyTimes = `0; ${interval}; 1`;
	const dur = `${speed}s`;

	return (
		<svg
			height={height}
			width={width}
			role="img"
			className={"loader-bar"}
			viewBox={`0 0 ${width} ${height}`}
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0"
				rx="4"
				ry="4"
				y="0"
				width={width}
				height={height}
				style={{ fill: `url(#${idGradient})` }}
			/>
			<defs>
				<linearGradient id={idGradient}>
					<stop offset="0%" stopColor={primaryColor} stopOpacity={primaryOpacity}>
						{animate && (
							<animate
								attributeName="offset"
								values={`${-gradientRatio}; ${-gradientRatio}; 1`}
								keyTimes={keyTimes}
								dur={dur}
								repeatCount="indefinite"
							/>
						)}
					</stop>

					<stop offset="50%" stopColor={secondaryColor} stopOpacity={secondaryOpacity}>
						{animate && (
							<animate
								attributeName="offset"
								values={`${-gradientRatio / 2}; ${-gradientRatio / 2}; ${1 +
									gradientRatio / 2}`}
								keyTimes={keyTimes}
								dur={dur}
								repeatCount="indefinite"
							/>
						)}
					</stop>

					<stop offset="100%" stopColor={primaryColor} stopOpacity={primaryOpacity}>
						{animate && (
							<animate
								attributeName="offset"
								values={`0; 0; ${1 + gradientRatio}`}
								keyTimes={keyTimes}
								dur={dur}
								repeatCount="indefinite"
							/>
						)}
					</stop>
				</linearGradient>
			</defs>
		</svg>
	);
};

LoaderBar.defaultProps = {
	height: 18,
	width: "100%",
	primaryColor: "#f0f0f0",
	primaryOpacity: 1,
	animate: true,
	secondaryColor: "#e0e0e0",
	secondaryOpacity: 1,
	speed: 2,
	gradientRatio: 2,
	interval: 0.25,
};
LoaderBar.propTypes = {
	idGradient: PropTypes.string,
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	primaryColor: PropTypes.string,
	primaryOpacity: PropTypes.number,
	animate: PropTypes.bool,
	secondaryColor: PropTypes.string,
	secondaryOpacity: PropTypes.number,
	speed: PropTypes.number,
	gradientRatio: PropTypes.number,
	interval: PropTypes.number,
};

export default LoaderBar;
